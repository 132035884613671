export const BALL_ANIMATION_DURATION = 2000;

export const DEFAULT_COLOR_INDEX = [  
  'red',
  'blueGrey',
  'orange',
  'deepPurple',
  'lightBlue',
  'lightGreen',
  'yellow',
  'indigo',
  'cyan',
  'pink',
  'purple',
  'teal',
  'blue',
  'deepOrange',
  'green',
  'brown',
  'orange',
  'grey',
  'amber',
  'lime',
]