import React, { useEffect, useState } from 'react';
import "./fade.scss"

const Fade = ({ show, children, animation = ['fade-in', 'fade-out'] }) => {
  const [shouldRender, setRender] = useState(show);
  useEffect(() => {
    if (show) setRender(true);
  }, [show]);
  const onAnimationEnd = () => {
    if (!show) setRender(false);
  };
  return (
    shouldRender && (
      <div
        className={show ? animation[0] : animation[1] }
        onAnimationEnd={onAnimationEnd}
      >
        {children}
      </div>
    )
  );
};

export default Fade;