import {
  atom,
  selector,
  useRecoilState,
  useRecoilValue,
  useSetRecoilState,
} from 'recoil';
import { useCallback, useEffect, useRef } from 'react'
import { BALL_ANIMATION_DURATION, DEFAULT_COLOR_INDEX } from "../app-config";

export const playerArray = atom({
  key: 'playerArrayState',
  default: [1,2,3,4],
});

export const playerTotal = selector({
  key: 'playerTotalState',
  get: ({get}) => get(playerArray).length,
});


export const shuffledIndex = atom({
  key: 'shuffledIndexState',
  default: 0,
});

export const shuffledPlayers = selector({
  key: 'shuffledPlayerState',
  get: ({get}) => {
    get(shuffledIndex);
    const players = [...get(playerArray)]
    return players.sort(() => Math.random() - 0.5);
  },
});

export function useReshufflePlayer() {
  const [id, setRequestID] = useRecoilState(shuffledIndex)
  return () => {
    setRequestID(id + 1);
  };
}

export const ballColorIndexArray = atom({
  key: 'ballColorIndexArray',
  default: DEFAULT_COLOR_INDEX,
});

export function useShuffleBallColor() {
  const setColorArray = useSetRecoilState(ballColorIndexArray)
  const shuffleBallColor = useCallback(
    () => {
      const newArray = [...DEFAULT_COLOR_INDEX]

      newArray.sort(() => Math.random() - 0.5);
      setColorArray(newArray)
    },
    [setColorArray]
  )
  const resetToOrigin = useCallback(
    () => {
      setColorArray(DEFAULT_COLOR_INDEX)
    },
    [setColorArray]
  )
  return { shuffleBallColor, resetToOrigin };
}

//  global Animation flag 
export const AnimationBallFlag = atom({
  key: 'AnimationBallFlag',
  default: false,
});

export function useAnimateBall( duration = BALL_ANIMATION_DURATION ) {
  const [isAnimated, setAnimate] = useRecoilState(AnimationBallFlag)
  const trigger = useCallback(
    () => {
      if (isAnimated) {
        return;
      }
      setAnimate(true)
      setTimeout(() => {
        setAnimate(false)
      }, duration);
    },
    [duration, isAnimated, setAnimate],
  )
  return { trigger, isAnimated }
}

export const useAnimateBallCb = (cb) => {
  const pulse = useRecoilValue(AnimationBallFlag)
	const lastPulse = usePreviousValueOnly(pulse)
	useEffect(() => {
		if (lastPulse === false && pulse === true) {
			cb && cb()
		}
  }, [pulse, cb, lastPulse]);
}

export const usePreviousValueOnly = value => {
  const ref = useRef();
	useEffect(() => {
		ref.current = value
	},[value]);
	return ref.current
}
