import React from 'react';
import LOGO from "../assets/logo/picker-logo-op.svg";
import IconButton from '@material-ui/core/IconButton';
import OpacityIcon from '@material-ui/icons/Opacity';
import { useShuffleBallColor } from "../recoil";
import "../App.scss"

const Header = props => {
  const { shuffleBallColor, resetToOrigin } = useShuffleBallColor()
  return (
    <section className="header">
      <div className="header-brand">
        <img src={LOGO} alt="logo" onClick={resetToOrigin} />
        <div className="header-brand-text">
          <h2>PICK</h2>
          <h2>ORDERS</h2>
        </div>
      </div>
      <IconButton aria-label="colorButton" color="primary" style={{ color: "#607d8b" }} onClick={shuffleBallColor}>
        <OpacityIcon fontSize="inherit" style={{ fontSize: 40 }} />
      </IconButton>
    </section>
  )
}

export default Header;