// import logo from './assets/logo.svg';
import Controller from "./components/controller";
import Balls from "./components/balls";
import Header from "./components/header";


import './App.scss';

function App() {

  return (
    <div className="App">
      <Header />
      <div className="divider"></div>
      <section className="displayer">
        <Balls />
      </section>
      <Controller />
    </div>
  );
}

export default App;