import React, { useState, useRef, useMemo, useEffect } from 'react';
import Fade from "./fade";
import { playerArray, shuffledPlayers, playerTotal, useAnimateBallCb, ballColorIndexArray } from "../recoil";
import { useRecoilValue } from "recoil";
import { gradientColorMap, colorIndexArray } from "../utils/color-set";
import { BALL_ANIMATION_DURATION } from "../app-config";

import anime from "animejs";

import "../App.scss";

const BG_COUNT = 5;

const shuffle = (array) => {
  array.sort(() => Math.random() - 0.5);
}

const getGradientColorString = (colorArray = ['red', 'green', 'blue', 'purple']) => {
  const res = colorArray.map(color => gradientColorMap[color].join(',')).join(',')
  return res;
}

const getRandomColors = (count = BG_COUNT, colorName) => {
  const colorArrayCopy = [...colorIndexArray]
  const newColorArray = []
  shuffle(colorArrayCopy)
  let i = 0
  while (newColorArray.length < BG_COUNT - 1) {
    const element = colorArrayCopy[i];
    if (element === colorName) {
      i++;
      continue;
    }
    newColorArray.push(element)
    i++;
  }
  if (colorIndexArray.includes(colorName)) {
    newColorArray.push(colorName)
  } else {
    newColorArray.push(colorArrayCopy[i])
  }
  return newColorArray
}

const getAnimatedBallStyle = (colorName) => {
  const bgColors = getRandomColors(BG_COUNT, colorName)
  // console.log(bgColors);
  return {
    backgroundImage: `linear-gradient(45deg, ${getGradientColorString(bgColors)})`,
    backgroundSize: `${BG_COUNT * 2}00% ${BG_COUNT * 2}00%`,
  }
}

const Ball = ({ playerId }) => {
  const id = playerId - 1
  const orderArray = useRecoilValue(shuffledPlayers);
  const playerCount = useRecoilValue(playerTotal);
  const ballColorArray = useRecoilValue(ballColorIndexArray);
  const [animated, setAnimated] = useState(false);
  const [showText, setShowText] = useState(false);
  const [value, setValue] = useState(0);
  // const animated = useRecoilValue(AnimationBallFlag);
  const obj = useRef();
  const ref = useRef();
  const colorName = ballColorArray[id]
  const bgColor = gradientColorMap[colorName]
  const animatedStyle = useMemo(() => getAnimatedBallStyle(colorName), [colorName])
  const normalStyle = {
    backgroundImage: `linear-gradient(45deg, ${bgColor[0]},${bgColor[1]})`,
    backgroundSize: '100% 100%',
  }
  const play = () => {
    setAnimated(true)
    setShowText(false)
    obj.current = { value: 0 }
    anime({
      targets: ref.current,
      easing: 'linear',
      "background-position-x": ['0%', '100%'],
      "background-position-y": ['100%', '0%'],
      duration: BALL_ANIMATION_DURATION,
    })
    anime({
      targets: obj.current,
      value: 5,
      easing: 'linear',
      round: 1,
      loop: 10,
      duration: BALL_ANIMATION_DURATION / 10,
      update: function () {
        setValue(obj.current.value)
      }
    });
    setTimeout(() => {
      setAnimated(false)
      setShowText(true)
    }, BALL_ANIMATION_DURATION);
  }
  useAnimateBallCb(play)
  useEffect(
    () => {
      setShowText(false)
    },
    [playerCount]
  )
  return (
    <div ref={ref} key={id} className="ball" style={animated ? animatedStyle : normalStyle} onClick={play}>
      {
        <p className="ball-text">
          {value}
        </p>
      }
      {
        showText
          ? <p className="ball-text" style={{ opacity: showText ? 1 : 0 }}>
            {orderArray[id]}
          </p>
          : <p className="ball-text" style={{ opacity: animated ? 1 : 0 }}>
            {value}
          </p>
      }
    </div>
  )
}
const FadeBall = ({ ...props }) => {
  const [show, setShow] = useState(false);
  useEffect(
    () => setShow(true),
    []
  )
  return (
    <Fade show={show} animation={['fade-in-top', 'fade-out-bottom']}>
      <Ball {...props} />
    </Fade>
  )
}

const Balls = props => {
  const players = useRecoilValue(playerArray)
  const ballDoms = players.map(item => {
    return (
      <FadeBall key={item} playerId={item} />
    )
  })
  return (
    <div className="balls">
      {ballDoms}
    </div>
  )
}

export default Balls