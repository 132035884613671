import {  
  red,
  pink,
  purple,
  deepPurple,
  indigo,
  blue,
  lightBlue,
  cyan,
  teal,
  green,
  lightGreen,
  lime,
  yellow,
  amber,
  orange,
  deepOrange,
  brown,
  grey,
  blueGrey,
} from '@material-ui/core/colors';

import { DEFAULT_COLOR_INDEX } from "../app-config";

const PRIMARY = '500';
const BG = '100';
const TEXT = '800';
const BASIC = 'A400';
const BASIC_LIGHT = 'A100';

const colorObj = {
  red: red,
  pink: pink,
  purple: purple,
  deepPurple: deepPurple,
  indigo: indigo,
  blue: blue,
  lightBlue: lightBlue,
  cyan: cyan,
  teal: teal,
  green: green,
  lightGreen: lightGreen,
  lime: lime,
  yellow: yellow,
  amber: amber,
  orange: orange,
  deepOrange: deepOrange,
  brown: brown,
  grey: grey,
  blueGrey: blueGrey,
}

export const colorSet = {}
export const colorMap = {}
export const gradientColorMap = {}

for (const key in colorObj) {
  if (colorObj.hasOwnProperty(key)) {
    const color = colorObj[key];
    colorMap[key] = color[BASIC]
    gradientColorMap[key] = [color[BASIC], color[BASIC_LIGHT]]
    colorSet[key] = {
      primary: color[PRIMARY],
      bg: color[BG],
      text: color[TEXT],
    }
  }
}

export const colorIndexArray = DEFAULT_COLOR_INDEX;