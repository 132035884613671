import React, { useState } from 'react';
import { playerTotal, playerArray, useAnimateBall, useReshufflePlayer } from "../recoil";
import { useRecoilState, useRecoilValue } from "recoil";
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import CheckIcon from '@material-ui/icons/Check';
import CircularProgress from '@material-ui/core/CircularProgress';
import { BALL_ANIMATION_DURATION } from "../app-config";

import "../App.scss"

const Controller = () => {
  const [players, setPlayers] = useRecoilState(playerArray);
  const playCount = useRecoilValue(playerTotal);
  // const orders = useRecoilValue(shuffledPlayers);
  const refresh = useReshufflePlayer()
  const { trigger, isAnimated } = useAnimateBall();
  const [showSuccess, setShowSuccess] = useState(false);
  const sub = () => {
    if (isAnimated) {
      return;
    }
    if (playCount <= 1) {
      return;
    }
    const newPlayers = [...players]
    newPlayers.pop()
    setPlayers(newPlayers)
  }
  const add = () => {
    if (isAnimated) {
      return;
    }
    if (playCount >= 19) {
      return;
    }
    setPlayers([...players, playCount + 1])
  }
  const animateSuccess = () => {
    setShowSuccess(true)
    setTimeout(() => {
      setShowSuccess(false)
    }, 500);
  }
  const go = async () => {
    setTimeout(() => {
      animateSuccess();
    }, BALL_ANIMATION_DURATION);
    trigger()
    refresh()
  }
  return (
    <section className="controller">
      <ButtonGradient onClick={sub} color="red" >
        <RemoveIcon style={{ height: '100%' }} />
      </ButtonGradient>
      <div className="controller-count" onClick={go} style={showSuccess ? { background: "#69f0ae" } : {}}>
        {
          showSuccess
            ? <p className="controller-count-check">
              <CheckIcon style={{ height: '100%' }} fontSize="large" />
            </p>
            : <p className="controller-count-text">
              {playCount}
            </p>
        }
        {isAnimated && <CircularProgress size={98} className="fabProgress" />}
      </div>
      <ButtonGradient onClick={add} color="blue" >
        <AddIcon style={{ height: '100%' }} />
      </ButtonGradient>
    </section>
  )
}

const ButtonGradient = ({ children, onClick, color, }) => {
  const [animated, setAnimated] = useState(false);
  const colorStyle = animated ? `${color}-gradient-hl` : `${color}-gradient`
  const styleName = `gradientButton ${colorStyle}`
  const handleClick = () => {
    if (animated) {
      onClick && onClick()
      return;
    }
    setAnimated(true)
    onClick && onClick()
    setTimeout(() => {
      setAnimated(false)
    }, 200);
  }
  return (
    <div className={styleName} onClick={handleClick}>{children}</div>
  )
}

export default Controller